import React, { useState, useEffect } from 'react';
import { getToken } from 'actions/sessionActions';
import { Page, SearchBar } from 'components';
import { Results } from './components';
import { useHistory } from 'react-router-dom';
import { Col, Row, Button, Modal } from 'react-bootstrap';
import Reload from '../../components/Reload/Reload';
import Header from '../../components/Header/Header';
import NotifyModal from '../../components/NotifyModal/NotifyModal';
import ApiMServer from 'services/ApiMServer';

const CooperativeClass = () => {
  const history = useHistory();

  const user = JSON.parse(localStorage.getItem('userData'));
  if (user?.permission?.cooperatives?.read === false) {
    window.location.replace('/home');
  }

  const [units, setUnits] = useState([]);
  const [unitsToShow, setUnitsToShow] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchData, setSearchData] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [permission, setPermission] = useState(false);
  const [loading, setLoading] = useState(true);

  const [type, setType] = useState({ color: "", status: "" });
  const [modalShow, setModalShow] = useState(false);

  const handleAlertModalSuccess = (msg) => {
    setType({ color: "#198754", status: msg })
    setModalShow(true)
  }

  const handleAlertModalError = (msg) => {
    setType({ color: "red", status: msg })
    setModalShow(true)
  }

  const handleClickOpen = (id) => {
    setCompanyId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setCompanyId('');
    setOpen(false);
  };

  const getUnits = async () => {
    try {
      let { data } = await ApiMServer.get(`/company/v1/getcooperative`, { headers: { token: getToken() } });
      let sortUnits = data.sort((a, b) => {
        if (a.name === b.nameD)
          return 0;
        if (a.name < b.name)
          return -1;
        if (a.name > b.name)
          return 1;
      });
      setUnits(sortUnits);
      setUnitsToShow(sortUnits);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      alert("Erro ao Carregar!");
    }
  };

  const removeUnit = async () => {
    setOpen(false);
    try {
      await ApiMServer.post('/company/delete', { id: companyId, token: getToken() });
      getUnits();
      handleAlertModalSuccess('Removido com Sucesso!');
    } catch (e) {
      handleAlertModalError('Falha ao Remover!');
    }
  };

  useEffect(() => {
    getUnits()
  }, [])

  useEffect(() => {
    const { permission } = JSON.parse(localStorage.getItem('userData'))
    setPermission(permission?.units?.write)
  }, [])

  const handleSearch = () => {
    if (!searchData.trim()) {
      setUnitsToShow(units);
      return;
    }

    const usersSearch = units.filter((user) =>
      ['name', 'cnpj', 'ncontract'].some(
        (key) =>
          user?.[key] &&
          user[key].toUpperCase().includes(searchData.toUpperCase())
      )
    );
    setUnitsToShow(usersSearch);
  };

  const handleSearchData = (e) => {
    setSearchData(e.target.value)
  };

  const handleClick = () => {
    history.push('/cooperatives/add');
  };

  return (
    <Page className="p-3" title="Cooperativas">
      <Header page={'Cooperativas'} />

      {loading && <Reload />}

      <NotifyModal
        type={type}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <Row className="align-items-center">
        <Col xs={12} md={6}>
          <SearchBar
            onSearch={handleSearch}
            onSearchData={handleSearchData}
            searchData={searchData}
          />
        </Col>
        <Col xs={12} md={6} className="text-md-end text-center">
          <Button
            disabled={!permission}
            onClick={handleClick}
            variant="success"
          >
            Adicionar Cooperativa
          </Button>
        </Col>
      </Row>

      {unitsToShow && (
        <Results
          data={unitsToShow}
          onRemoveButton={handleClickOpen}
          permission={permission}
        />
      )}

      <Modal
        show={open}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Remover
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Confirma Remover a Empresa?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Não
          </Button>
          <Button variant="danger" onClick={removeUnit}>
            Sim
          </Button>
        </Modal.Footer>
      </Modal>

    </Page>
  );
};

export default CooperativeClass;
