import React, { useEffect, useState } from 'react';
import axios from 'utils/axios';
import { getToken } from 'actions/sessionActions';
import { Page, NotifyModal } from 'components';
import FileUpload from '../../components/FileUpload/FileUpload'
import { Form, Equipments, Cooperatives } from './components';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import { Card, Row, Col, Tabs, Tab } from 'react-bootstrap';
import ClipLoader from "react-spinners/ClipLoader";

import Header from '../../components/Header/Header';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { Button, Divider, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ApiMServer from 'services/ApiMServer';

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3, 3, 6, 3)
  },
  aboutProject: {
    marginTop: theme.spacing(3)
  },
  actions: {
    marginTop: theme.spacing(3)
  },
  button: {
    marginBottom: '20px',
    marginRight: '20px'
  },
  dialogList: {
    textAlign: 'center',
    marginRight: '80px',
    marginLeft: '80px'
  },
  alert: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  ativaction: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(2)
  },
  label: {
    marginBottom: theme.spacing(3)
  },
  typography: {
    marginBottom: theme.spacing(2)
  },
  rmvButton: {
    borderColor: '#FE2020',
    color: '#FE2020',
    background: 'transparent'
  }
}));

const CooperativesDetail = (props) => {
  const classes = useStyles();
  const _id = useParams().id;

  const data = JSON.parse(localStorage.getItem('userData'));
  if (data?.permission?.cooperatives?.read === false) {
    window.location.replace('/home');
  }
  const idUser = data?._id;
  const email = data?.email;

  const [openDialogActivation, setOpenDialogActivation] = React.useState(false);
  const [daystoactivate, setDaystoactivate] = React.useState('');
  const [stateWifi, setStateWifi] = useState(0);
  const [statePh, setStatePh] = useState(0);
  const [equipments, setEquipments] = useState([]);
  const [serialsnumbers, setSerialsNumbers] = useState([]);
  const [id, setId] = useState('');
  const [permission, setPermission] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ "message": '', "type": '' });
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [chave, setChave] = useState('equipamentos');
  const [unit, setUnit] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [unitsCompany, setUnitsCompany] = useState([]);
  const [rentalEquipments, setRentalEquipments] = useState([])
  const [ownerEquipments, setOwnerEquipments] = useState([])

  let [loading, setLoading] = useState(false);

  const handleStatateWifi = () => {
    setStateWifi(stateWifi === 0 ? 1 : stateWifi === 1 ? 2 : 0)
  }

  const handleStatatePh = () => {
    setStatePh(statePh === 0 ? 1 : statePh === 1 ? 2 : 0)
  }

  //--------------------------------------------------------------
  const [type, setType] = useState({ "color": "", "status": "" });
  const [modalShow, setModalShow] = useState(false);

  const handleAlertModalSuccess = (msg) => {
    setType({ "color": "#198754", "status": msg })
    setModalShow(true)
  }

  const handleAlertModalError = (msg) => {
    setType({ "color": "red", "status": msg })
    setModalShow(true)
  }
  //--------------------------------------------------------------

  const [units, setUnits] = useState({
    typeCompany: false,
    crossCheck: false,
    equipments: [],
    localEquipament: [],
    units: [],
    id: '',
    name: '',
    cnpj: '',
    ncontract: '',
    fone: '',
    email: '',
    password: '',
    secretOnlyKey: '',
    accessSecretOnlyKey: '',
    dominioEmail: '',
    createdAt: '',
    updatedAt: '',
    restrictAcess: false
  });

  const [emails, setEmails] = useState([])

  useEffect(() => {
    (async () => {
      await axios(process.env.REACT_APP_API_MOTOMCO, getToken())
        .get('/users/manager', { "headers": { "id": _id } })
        .then((response) => {
          var data = response.data;
          setEmails(data);
        });
    })();
  }, [_id]);

  const [checkbox, setCheckbox] = useState({
    "simt": true,
    "semt": true,
    "bitm": true,
    "cpmt": true,
    "eemt": true,
    "esmr": true
  });

  const handleChangeModeAll = (mode) => {
    let validate = window.confirm('Confirma essa ação para todos os equipamentos desta empresa?')
    if (validate) {
      let list = equipments.map(item => item.serialnumber);
      (async () => {
        await axios(process.env.REACT_APP_API_GLOBAL_SERVER, getToken()).post(
          `/equipment/updatemodegroup`, { "list": list, "mode": mode }
        )
        getEquipments();
      })()
    }
    return;
  }

  const handleOpenUpdateActivation = () => {
    setOpenDialogActivation(true);
  };

  const handleCloseUpdateActivation = () => {
    setOpenDialogActivation(false);
  };

  const handleAddEquipments = () => {
    if (!selectedEquipments.length) return;
    (async () => {
      await axios(process.env.REACT_APP_API_MOTOMCO, getToken())
        .post('/company/addequipment', {
          "_id": id,
          "serialnumber": selectedEquipments
        })
        .then((response) => {
          if (response.status === 200) {
            setKey(!key);
            for (var i in selectedEquipments) {
              units.equipments.push(selectedEquipments[i]);
            }
            getEquipments();
            alert("Adicionado com sucesso!")
          } else if (response.status === 202) {
            let confirm = window.confirm(`Cadastrado em ${response.data.name}, ir para a empresa?`);
            if (confirm) {
              if (response.data.typeCompany === true) {
                window.location.href = `/cooperatives/${response.data._id}`
              } else {
                window.location.href = `/units/${response.data._id}`
              }
            }
            return
          }
        });
    })();
  };

  const handleRemoveEquipments = async () => {
    if (!selectedEquipments.length) return;
    if (!window.confirm("Confirma remover?")) {
      return;
    } else {
      try {
        await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).post('/company/rmequipment', {
          "_id": id,
          "serialnumber": selectedEquipments
        });
        setKey(!key);
        selectedEquipments.forEach((i) => {
          const index = units.equipments.indexOf(i);
          if (index > -1) {
            units.equipments.splice(index, 1);
          }
        })
        getEquipments();
        handleAlertModalSuccess("Removido com sucesso!");
      } catch (error) {
        handleAlertModalError('Falha ao atualizar!');
      }
    };
  };

  const handleAddUnits = async () => {
    if (!selectedUnits.length) return;
    var idUnits = [];
    for (let i = 0; i < selectedUnits.length; i++) {
      var value = selectedUnits[i];
      var j = unidades.filter((item) => item.name === value).map((i) => {
        return i._id;
      })
      idUnits.push(j[0]);
    }
    try {
      var data = await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).post('/company/addunit', {
        "id": id,
        "units": idUnits
      })
      if (data.status === 200) {
        setKeyTwo(!keyTwo);
        for (var i in selectedUnits) {
          units.units.push(selectedUnits[i]);
        }
        handleCompanyDetail();
        handleAlertModalSuccess("Adicionado com sucesso!");
      }
    } catch (error) {
      handleAlertModalError('Falha ao atualizar!')
    }
  };

  const handleRemoveUnits = async () => {
    if (!selectedUnits.length) return;
    if (window.confirm("Confirma remover?")) {
      var idUnits = [];
      for (let i = 0; i < selectedUnits.length; i++) {
        var value = selectedUnits[i];
        var j = unidades.filter((item) => item.name === value).map((i) => {
          return i._id;
        })
        idUnits.push(j[0]);
      }
      try {
        var data = await axios(process.env.REACT_APP_API_MOTOMCO, getToken())
          .post('/company/rmunit', {
            "id": id,
            "units": idUnits
          })
        if (data.status === 200) {
          setKeyTwo(!keyTwo);
          selectedUnits.forEach((i) => {
            const index = units.units.indexOf(i);
            if (index > -1) {
              units.units.splice(index, 1);
            }
          })
          handleGetCompany();
          handleCompanyDetail();
          handleAlertModalSuccess("Removido com sucesso!");
        }
      } catch (error) {
        handleAlertModalError('Falha ao atualizar!')
      }
    };
  };

  const handleRemoveAllUnits = async () => {
    if (window.confirm("Confirma remover tudo?")) {
      try {
        await axios(process.env.REACT_APP_API_MOTOMCO, getToken())
          .post('/company/rmallunit', {
            "id": id,
          }).then((response) => {
            if (response.status === 200) {
              handleGetCompany();
              handleCompanyDetail();
              handleAlertModalSuccess("Removido com sucesso!");
            }
          });
      } catch (error) {
        handleAlertModalError('Falha ao atualizar!')
      }
    };
  };

  const handleDaysChange = (event, field, value) => {
    event.persist && event.persist();
    setDaystoactivate(value);
  };

  useEffect(() => {
    const { id } = props.match.params;
    setId(id);
  }, [props]);

  const handleGetCompany = async () => {
    try {
      await axios(process.env.REACT_APP_API_MOTOMCO, getToken())
        .get(`/company/get`)
        .then((response) => {
          var u = response.data
          setUnidades(response.data)
          u = u.filter(unit => unit.name).map(item => item.name)
          setUnit(u ? u : []);
        });
    } catch (error) {
      handleAlertModalError('Falha ao atualizar!')
    }
  }

  const handleCompanyDetail = async () => {
    try {
      await axios(process.env.REACT_APP_API_MOTOMCO, getToken())
        .post(`/company/v1/getcooperativedetail`, { "id": _id })
        .then((response) => {
          var unit = response.data;
          unit['password'] = "";
          setUnits(unit);
          setUnitsCompany(unit.units)

          if (response.data.equipments) {
            axios(process.env.REACT_APP_API_GLOBAL_SERVER, getToken())
              .post(`/equipment/filter`, { "filter": response.data.equipments })
              .then((response) => {
                setEquipments(response.data);
                handlefilterEquipments(response.data);
                localStorage.setItem("tempEquipments", JSON.stringify(response.data));
              });
          }
        });
    } catch (error) {
      handleAlertModalError('Falha ao atualizar!')
    }
  }

  const handlefilterEquipments = (equipamentos) => {
    const rentalEquipments = equipamentos
      .filter(equip => equip.mode === "RENTAL")
      .map(equip => equip.serialnumber)

    setRentalEquipments(rentalEquipments)

    const ownerEquipments = equipamentos
      .filter(equip => equip.mode === "OWNER")
      .map(equip => equip.serialnumber)
    setOwnerEquipments(ownerEquipments)
  }

  const handlePlanChangeRental = (plan) => {
    handlePlan(plan, rentalEquipments)
  };

  const handlePlanChangeOwner = (plan) => {
    handlePlan(plan, ownerEquipments)
  };

  const handlePlan = async (newPlan, serials) => {
    let validate = window.confirm(`Confirma alterar para o plano ${newPlan} todos os equipamentos desta Cooperativa?`)
    if (validate) {
      setEquipments((prevEquipments) => {
        const updatedEquipments = prevEquipments.map((equipment) => {
          if (serials.includes(equipment.serialnumber)) {
            return { ...equipment, plan: newPlan };
          }
          return equipment;
        });
        return updatedEquipments;
      });

      try {
        await axios(process.env.REACT_APP_API_GLOBAL_SERVER, getToken()).post(
          `/equipment/updateplan`,
          {
            filter: serials,
            plan: newPlan
          }
        );
        handleAlertModalSuccess("Atualizado com sucesso!");
      } catch (error) {
        handleAlertModalError('Falha ao atualizar!');
      }
    } else {
      return
    }
  };

  useEffect(() => {
    const { permission } = JSON.parse(localStorage.getItem('userData'));
    setPermission(permission.cooperatives.write);
    handleGetCompany();
    handleCompanyDetail();
  }, []);

  const getEquipments = () => {
    (async () => {
      await axios(process.env.REACT_APP_API_GLOBAL_SERVER, getToken())
        .post(`/equipment/filter`, { "filter": units.equipments })
        .then((response) => {
          setEquipments(response.data);
          handlefilterEquipments(response.data)
        });
    })();
  };

  const removeEquipment = (serialnumber) => {
    let confirm = window.confirm(`Confirma remover o equipamento ${serialnumber}`);
    if (confirm) {
      (async () => {
        await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).post(
          `/company/rmequipment`, { "_id": id, "serialnumber": serialnumber }
        );
        const index = units.equipments.indexOf(serialnumber);
        if (index > -1) {
          units.equipments.splice(index, 1);
        }
        getEquipments();
      })();
    }
  };

  const removeUnit = async (_id) => {
    let confirm = window.confirm(`Confirma remover a unidade?`);
    if (confirm) {
      try {
        await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).post(`/company/rmunit`, { "id": id, "units": _id })
        handleCompanyDetail();
        handleAlertModalSuccess("Removido com sucesso!");
      } catch (error) {
        handleAlertModalError('Falha ao atualizar!')
      }
    }
  };

  const updateDaysActivation = async () => {
    handleCloseUpdateActivation();

    if (!daystoactivate) {
      alert('O campo dias para Ativação deve ser preenchido!');
      return;
    }

    let value = parseInt(daystoactivate);
    if (isNaN(value)) {
      alert('O campo dias para Ativação deve ser preenchido com um número inteiro!');
      return;
    }

    if (value < 0 || value > 4095) {
      alert('O número de dias para Ativação deve estar entre 0 e 4095!');
      return;
    }

    try {
      await axios(process.env.REACT_APP_API_GLOBAL_SERVER, getToken()).post(
        '/equipment/updatedaysactivationCompany',
        {
          "list": units.equipments,
          "daystoactivate": Number(daystoactivate).toString(16).toUpperCase()
        }
      );
      getEquipments();
    } catch (error) {
      handleAlertModalError('Falha ao atualizar!')
    }
  };

  useEffect(() => {
    (async () => {
      await axios(process.env.REACT_APP_API_GLOBAL_SERVER, getToken())
        .get('/equipment/serialsnumber')
        .then((response) => {
          var data = response.data;
          data.sort(function (a, b) {
            return a.split('-')[1] - b.split('-')[1];
          }).sort();
          setSerialsNumbers(data);
        });
    })();
  }, []);

  const handleFieldChange = (event, field, value) => {
    setUnits((values) => ({
      ...values,
      [field]: value
    }));
  };

  const handleCheckBoxdChange = (field, value) => {
    setCheckbox((values) => ({
      ...values,
      [field]: value
    }));
  };

  const updateAddressAndLocation = (newAddress, newLat, newLng) => {
    console.log(newAddress, newLat, newLng);
    setUnits(prevUnits => ({
      ...prevUnits,
      address: newAddress,
      location: {
        lat: String(newLat),
        lng: String(newLng)
      }
    }));
  };

  const filterData = () => {
    var fequips = [];

    if (checkbox.simt) {
      Array.prototype.push.apply(fequips, equipments.filter(e => e.serialnumber.split("-")[0] === "SIMT"));
    }
    if (checkbox.semt) {
      Array.prototype.push.apply(fequips, equipments.filter(e => e.serialnumber.split("-")[0] === "SEMT"));
    }
    if (checkbox.bitm) {
      Array.prototype.push.apply(fequips, equipments.filter(e => e.serialnumber.split("-")[0] === "BIMT"));
    }
    if (checkbox.cpmt) {
      Array.prototype.push.apply(fequips, equipments.filter(e => e.serialnumber.split("-")[0] === "CPMT"));
    }
    if (checkbox.eemt) {
      Array.prototype.push.apply(fequips, equipments.filter(e => e.serialnumber.split("-")[0] === "EEMT"));
    }
    if (checkbox.esmr) {
      Array.prototype.push.apply(fequips, equipments.filter(e => e.serialnumber.split("-")[0] === "ESMR"));
    }

    if (stateWifi === 1) {
      fequips = fequips.filter(v => v.wf === "OK")
    } else if (stateWifi === 2) {
      fequips = fequips.filter(v => v.wf !== "OK")
    }

    if (statePh === 1) {
      fequips = fequips.filter(v => v.phcalib === "OK")
    } else if (statePh === 2) {
      fequips = fequips.filter(v => v.phcalib !== "OK")
    }
    return fequips;
  }

  const handleSubmit = async () => {
    var data = units;
    delete data["equipments"];
    delete data["createdAt"];
    delete data["updatedAt"];
    delete data["__v"];

    data.idUser = idUser

    try {
      await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).post('/company/update', data)
      handleAlertModalSuccess('Atualizado com sucesso!');
    } catch (e) {
      handleAlertModalError('Falha ao atualizar!')
    }
    try {
      await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).post(
        `/company/creategestor`, { "emails": emails, "id": units._id })
    } catch (error) {
      console.log(error);
      handleAlertModalError('Falha ao atualizar!')
    }
    window.scroll({ "top": 0, "left": 0, "behavior": 'smooth' });
  };

  const handleGetRepor = async () => {
    setLoading(true);
    try {
      window.open(`${process.env.REACT_APP_API_MOTOMCO}/managementReport/${_id}`)
      setLoading(false);
    } catch (error) {
      handleAlertModalError('Falha ao atualizar!');
      setLoading(false);
    }
  }

  const handleOpenCompany = async () => {
    try {
      await ApiMServer.post(`/company/addgestor`, { token: getToken(), id: _id, emails: email });
      window.open(`${process.env.REACT_APP_SGU}`);
    } catch (error) {
      console.log(error);
    }
  }

  const [charts, setCharts] = useState("");

  const handleDeleteCharts = async () => {
    var confirm = window.confirm("Confirma apagar curva?")
    if (!confirm || !charts) {
      return
    }
    try {
      await ApiMServer.delete(`/api/v1/deletecharts?_id=${charts._id}`);
      setCharts("")
      handleAlertModalSuccess("Apagado com sucesso!")
    } catch (error) {
      handleAlertModalError("Erro ao apagar!")
    }
  }

  const handleGetChart = async () => {
    if (!_id) {
      return
    }
    try {
      var { data } = await ApiMServer.get(`/api/v1/getcharts?company_id=${_id}`);
      if (data) {
        setCharts(data)
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    handleGetChart()
  }, [])

  const [key, setKey] = useState(false);
  const [keyTwo, setKeyTwo] = useState(false);
  const [openUnits, setOpenUnits] = useState(false);

  useEffect(() => {
    if (selectedUnits.length) {
      setOpenUnits(true)
    } else {
      setOpenUnits(false)
    }
  }, [selectedUnits])

  return (
    <Page className="p-3" title="Cooperativas">

      <Header page={"Cooperativas"} />

      <NotifyModal
        type={type}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <Card className='mb-2 mt-2 p-2'>
        <Row>
          <Col>

            <Button
              className='ms-2 mt-2 mb-2'
              disabled={!permission}
              color="primary"
              variant="contained"
              onClick={() => { handleOpenCompany() }}
            >
              Visualização do Cliente
            </Button>

            <Button
              className='ms-2 mt-2 mb-2'
              disabled={!permission}
              color="primary"
              variant="contained"
              onClick={() => { handleGetRepor() }}
            >
              {loading ? <ClipLoader size={20} color="white" /> : 'Relatório Gerencial'}
            </Button>

          </Col>
        </Row>
      </Card>

      <Form
        alertMessage={alertMessage}
        className={classes.aboutProject}
        fieldChange={handleFieldChange}
        onSubmit={handleSubmit}
        handleAlertModalError={handleAlertModalError}
        units={units}
        permission={permission}
        setEmails={setEmails}
        emails={emails}
        setUnits={setUnits}
        setLocation={updateAddressAndLocation}
      />

      <Row>
        <Col>
          <Autocomplete
            multiple
            key={key}
            id="combo-box"
            options={serialsnumbers}
            getOptionLabel={(option) => option}
            style={{ "marginBottom": 10, "width": '100%' }}
            renderInput={(params) => (
              <TextField {...params} label="Equipamentos" variant="outlined" />
            )}
            onChange={(event, newValue) => {
              setSelectedEquipments(newValue);
            }}
          />

          <Button
            disabled={permission === true ? false : true}
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={handleAddEquipments}>
            Adicionar Equipamentos
          </Button>

          {/*           <Button
            disabled={permission === true ? false : true}
            className={classes.button}
            color="primary"
            variant="outlined"
            onClick={handleRemoveEquipments}>
            Remover Equipamentos
          </Button> */}

        </Col>
        <Col>
          {units && units.typeCompany ?
            <div>
              <Autocomplete
                multiple
                key={key}
                open={openUnits}
                onOpen={() => setOpenUnits(true)}
                //onClose={() => setOpenUnits(false)}
                id="combo-box"
                options={unit}
                getOptionLabel={(option) => option}
                style={{ marginBottom: 10, width: '100%' }}
                renderInput={(params) => (
                  <TextField {...params} label="Unidades" variant="outlined" />
                )}
                onChange={(event, newValue) => {
                  setSelectedUnits(newValue);
                  //setOpenUnits(true)
                }}
              />
              <Button
                disabled={permission === true ? false : true}
                className={classes.button}
                color="primary"
                variant="contained"
                onClick={handleAddUnits}>
                Adicionar Unidades
              </Button>
              <Button
                disabled={permission === true ? false : true}
                className={classes.button}
                color="primary"
                variant="outlined"
                onClick={handleRemoveUnits}>
                Remover Unidades
              </Button>
              <Button
                disabled={permission === true ? false : true}
                className={classes.button}
                color="primary"
                variant="outlined"
                onClick={handleRemoveAllUnits}>
                Remover Todas
              </Button>
            </div>
            : null}
        </Col>
      </Row>

      <hr />
      <Typography variant="h5" className={classes.typography}>
        Curva personalizada
      </Typography>

      {/* Type = CUSTOM, INMETRO, COMPLEMENT */}
      <FileUpload
        type={"CUSTOM"}
        company={_id}
        permission={permission}
        handleGetChart={handleGetChart}
        setAlertMessage={setAlertMessage}
        handleAlertModalError={handleAlertModalError}
        handleAlertModalSuccess={handleAlertModalSuccess}
      />

      {charts._id ?
        <>
          <Typography style={{ marginTop: 10 }} variant="h5" className={classes.typography}>
            {`Curva existente: ${charts?.chartCustom[0]?.hash}`}
          </Typography>
          <Button
            disabled={permission === true ? false : true}
            className={classes.rmvButton}
            variant="outlined"
            onClick={() => {
              handleDeleteCharts()
            }}
          >
            Apagar curva existente
          </Button>
        </>
        :
        <Typography
          className={classes.typography}
          style={{ marginTop: 10 }}
          variant="h5"
        >
          {`Nenhuma curva personalizada`}
        </Typography>
      }
      <hr />

      <Button
        disabled={permission === true ? false : true}
        className='mb-2 mt-2'
        color="primary"
        variant="contained"
        onClick={() => {
          localStorage.setItem("EQUIP_FILTER", JSON.stringify(units.equipments))
          localStorage.setItem("EQUIPMENTS", JSON.stringify(equipments))
        }}
        component={RouterLink}
        to={'/relatorio'}>
        Relatório de Ativação
      </Button>

      <Divider />

      <div className={classes.ativaction}>
        <Typography variant="h5" className={classes.typography}>
          Alterar dias de ativação dos equipamentos empresa
        </Typography>

        <div className={classes.center}>
          <TextField
            disabled={permission === true ? false : true}
            label="dias"
            name="dias"
            variant="outlined"
            onChange={(event) =>
              handleDaysChange(event, 'daystoactivate', event.target.value)
            }
          />

          <Button
            disabled={permission === true ? false : true}
            style={{ marginTop: 8, marginLeft: 10 }}
            onClick={handleOpenUpdateActivation}
            className={classes.button}
            color="primary"
            variant="contained">
            Alterar
          </Button>
        </div>
      </div>

      {rentalEquipments.length > 0 &&
        <div className={classes.ativaction}>
          <Typography variant="h5" className={classes.typography}>
            Escolha um plano para todos os equipamentos de locação
          </Typography>
          <div className={classes.center}>
            <Button
              disabled={permission === true ? false : true}
              style={{ marginTop: 8, marginLeft: 10 }}
              onClick={() => handlePlanChangeRental('NORMAL')}
              className={classes.button}
              color="primary"
              variant="contained">
              NORMAL
            </Button>
            <Button
              disabled={permission === true ? false : true}
              style={{ marginTop: 8, marginLeft: 10 }}
              onClick={() => handlePlanChangeRental('FOB')}
              className={classes.button}
              color="primary"
              variant="contained">
              FOB
            </Button>
          </div>
        </div>
      }

      {ownerEquipments.length > 0 &&
        <div className={classes.ativaction}>
          <Typography variant="h5" className={classes.typography}>
            Escolha um plano para todos os equipamentos proprietários
          </Typography>
          <div className={classes.center}>
            <Button
              disabled={permission === true ? false : true}
              style={{ marginTop: 8, marginLeft: 10 }}
              onClick={() => handlePlanChangeOwner('NORMAL')}
              className={classes.button}
              color="primary"
              variant="contained">
              NORMAL
            </Button>
            <Button
              disabled={permission === true ? false : true}
              style={{ marginTop: 8, marginLeft: 10 }}
              onClick={() => handlePlanChangeOwner('PLUS')}
              className={classes.button}
              color="primary"
              variant="contained">
              PLUS
            </Button>
            <Button
              disabled={permission === true ? false : true}
              style={{ marginTop: 8, marginLeft: 10 }}
              onClick={() => handlePlanChangeOwner('FLEX')}
              className={classes.button}
              color="primary"
              variant="contained">
              FLEX
            </Button>
            <Button
              disabled={permission === true ? false : true}
              style={{ marginTop: 8, marginLeft: 10 }}
              onClick={() => handlePlanChangeOwner('PREMIUM')}
              className={classes.button}
              color="primary"
              variant="contained">
              PREMIUM
            </Button>
          </div>
        </div>
      }

      <div className={classes.ativaction}>
        <Typography variant="h5" className={classes.typography}>
          Alterar modo equipamentos empresa
        </Typography>
        <div className={classes.center}>
          <Button
            disabled={permission === true ? false : true}
            style={{ marginTop: 8, marginLeft: 10 }}
            onClick={() => handleChangeModeAll('RENTAL')}
            className={classes.button}
            color="primary"
            variant="contained">
            Todos para locação
          </Button>
          <Button
            disabled={permission === true ? false : true}
            style={{ marginTop: 8, marginLeft: 10 }}
            onClick={() => handleChangeModeAll('OWNER')}
            className={classes.button}
            color="primary"
            variant="contained">
            Todos para proprietário
          </Button>
          <Button
            disabled={permission === true ? false : true}
            style={{ marginTop: 8, marginLeft: 10 }}
            onClick={() => handleChangeModeAll('ISENTKEY')}
            className={classes.button}
            color="primary"
            variant="contained">
            Isenção de taxas
          </Button>

        </div>
      </div>

      <Divider />

      <div style={{ width: '100%', display: 'flex' }}>

        <div>
          <Checkbox
            checked={checkbox.simt}
            onChange={(event) => handleCheckBoxdChange('simt', event.target.checked)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <Typography variant="inherit">SIMT</Typography>
        </div>

        <div>
          <Checkbox
            checked={checkbox.semt}
            onChange={(event) => handleCheckBoxdChange('semt', event.target.checked)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <Typography variant="inherit">SEMT</Typography>
        </div>

        <div>
          <Checkbox
            checked={checkbox.bitm}
            onChange={(event) => handleCheckBoxdChange('bitm', event.target.checked)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <Typography variant="inherit">BIMT</Typography>
        </div>

        <div>
          <Checkbox
            checked={checkbox.eemt}
            onChange={(event) => handleCheckBoxdChange('eemt', event.target.checked)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <Typography variant="inherit">EEMT</Typography>
        </div>


        <div>
          <Checkbox
            checked={checkbox.cpmt}
            onChange={(event) => handleCheckBoxdChange('cpmt', event.target.checked)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <Typography variant="inherit">CPMT</Typography>
        </div>

        <div>
          <Checkbox
            checked={checkbox.esmr}
            onChange={(event) => handleCheckBoxdChange('esmr', event.target.checked)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <Typography variant="inherit">ESMR</Typography>
        </div>

        <div>
          <Checkbox
            checked={stateWifi === 1 ? true : stateWifi === 2 ? false : null}
            indeterminate={stateWifi === 0}
            onChange={handleStatateWifi}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <Typography variant="inherit">Wifi</Typography>
        </div>

        <div>
          <Checkbox
            checked={statePh === 1 ? true : statePh === 2 ? false : null}
            indeterminate={statePh === 0}
            onChange={handleStatatePh}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <Typography variant="inherit">PH Calibrado</Typography>
        </div>
      </div>

      <Tabs id="controlled-tab-example"
        className="mb-4 mt-4"
        defaultActiveKey={chave}
        activeKey={chave}
        onSelect={(key) => setChave(key)}
      >
        <Tab eventKey="equipamentos" title={"Equipamentos".toUpperCase()}>
          {equipments && equipments.length ?
            <Equipments
              className={classes.results}
              data={filterData()}
              onRemoveButton={removeEquipment}
              permission={permission}
            />
            : <h5>Sem equipamentos cadastrados.</h5>}
        </Tab>

        {unitsCompany && unitsCompany.length ?
          <Tab eventKey="unidades" title={"Unidades".toUpperCase()}>
            <Cooperatives
              className={classes.results}
              data={unitsCompany}
              onRemoveButton={removeUnit}
              permission={permission}
            />
          </Tab>
          : null}
      </Tabs>

      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleCloseUpdateActivation}
        open={openDialogActivation}>
        <DialogTitle id="alert-dialog-title">{'Alterar'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja alterar os dias de ativação de todos os equipamentos da{' '}
            {units.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleCloseUpdateActivation}>
            Cancelar
          </Button>
          <Button autoFocus color="primary" onClick={updateDaysActivation}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default CooperativesDetail;
