import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { Button } from 'react-bootstrap';

const Header = (props) => {

  const { permission } = JSON.parse(localStorage.getItem('userData'));
  const history = useHistory();

  const handleClick = () => {
    history.push('/units/add');
  };

  return (
    <div>
      <Grid
        alignItems="flex-end"
        container
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item>
          <Typography
            component="h2"
            gutterBottom
            variant="overline"
          >
            Gerenciamento
          </Typography>
          <Typography
            component="h1"
            variant="h3"
          >
            Unidades
          </Typography>
        </Grid>
        <Grid item>
          <Button
            disabled={permission?.units?.write === true ? false : true}
            onClick={handleClick}
            variant="success"
          >
            Adicionar Unidades
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default Header;
