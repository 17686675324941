import React, { useState, useEffect } from 'react';
import { getToken } from 'actions/sessionActions';
import { Page } from 'components';
import { MdGroup, MdStorage, MdLock, MdExplicit } from "react-icons/md";
import { Button, Card, Col, Row } from 'react-bootstrap'
import CertificadoDash from './components/CertificadoDash';
import { HorizontalBar } from 'react-chartjs-2';

import Reload from '../../components/Reload/Reload';
import Header from 'components/Header/Header';
import ApiMServer from 'services/ApiMServer';
import ApiGServer from 'services/ApiGServer';
import ApiGSenha from 'services/ApiGSenha';
import Api from 'services/Api';

const options = {
  scales: {
    y: [{
      ticks: {
        beginAtZero: true
      },
      maxBarThickness: 30
    }],
    x: [{
      ticks: {
        beginAtZero: true
      }
    }]
  }
};

const Home = () => {
  const user = JSON.parse(localStorage.getItem('userData'));

  if (user?.permission?.home?.read === false) {
    let keys = Object.keys(user?.permission)
    for (let i = 0; i < keys.length; i++) {
      let page = keys[i];
      if (user.permission[page].read) {
        window.location.replace('/' + page)
        break;
      }
    }
  }

  const [n_users, setNusers] = useState('--');
  const [n_measurements, setNMeasurements] = useState('--');
  const [n_unblocks, setNUnblocks] = useState('--');

  const [loading, setLoading] = useState(true);

  const [equipmentMetrics, setEquipmentMetrics] = useState({
    simt: { n: 0, softVesions: {}, labels: [], data: [] },
    bimt: { n: 0, softVesions: {}, labels: [], data: [] },
    cpmt: { n: 0, softVesions: {}, labels: [], data: [] },
    eemt: { n: 0, softVesions: {}, labels: [], data: [] },
    esmr: { n: 0, softVesions: {}, labels: [], data: [] },
  });

  useEffect(() => {
    const handleGetUser = async () => {
      const storedUser = localStorage.getItem('userData');
      if (!storedUser) return;

      try {
        const { _id } = JSON.parse(storedUser);
        const { data: newUserData } = await ApiMServer.get(`/users/${_id}`);

        if (newUserData) {
          const updatedUser = {
            ...JSON.parse(storedUser),
            ...newUserData,
          };
          localStorage.setItem('userData', JSON.stringify(updatedUser));
        }
      } catch (error) {
        console.error('Erro ao buscar dados do usuário:', error);
      }
    };
    handleGetUser()

    const getAll = async () => {
      setLoading(true)

      try {
        let unlockcount = await ApiGSenha.get('/log/unlockcount', getToken);
        setNUnblocks(unlockcount?.data?.count);

        let dashboard = await ApiMServer.get('/dashboard', getToken);
        setNusers(dashboard?.data?.n_users);

        let measurements = await Api.get('/measurements/count', getToken);
        setNMeasurements(measurements?.data?.n_measurements);

        let softwares = await ApiGServer.get('/equipment/metrics');
        const data = softwares.data;

        const sortVersionsDescending = (versions) => {
          return Object.entries(versions)
            .sort((a, b) => b[1] - a[1])
            .reduce((acc, [key, value]) => {
              acc[key] = value;
              return acc;
            }, {});
        };

        const sortedSimt = sortVersionsDescending(data.simt.softVesions);
        data.simt.labels = Object.keys(sortedSimt);
        data.simt.data = Object.values(sortedSimt);

        const sortedBimt = sortVersionsDescending(data.bimt.softVesions);
        data.bimt.labels = Object.keys(sortedBimt);
        data.bimt.data = Object.values(sortedBimt);

        const sortedCpmt = sortVersionsDescending(data.cpmt.softVesions);
        data.cpmt.labels = Object.keys(sortedCpmt);
        data.cpmt.data = Object.values(sortedCpmt);

        const sortedEemt = sortVersionsDescending(data.eemt.softVesions);
        data.eemt.labels = Object.keys(sortedEemt);
        data.eemt.data = Object.values(sortedEemt);

        const sortedEsmr = sortVersionsDescending(data.esmr.softVesions);
        data.esmr.labels = Object.keys(sortedEsmr);
        data.esmr.data = Object.values(sortedEsmr);

        setEquipmentMetrics(data);
        localStorage.setItem('equipMetrics', JSON.stringify(data));
        setLoading(false);

      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    getAll()
  }, []);

  const handlePlanilha = async (serial) => {
    try {
      window.open(`${process.env.REACT_APP_API_MOTOMCO}/dashboard/activelogsfilter?typeSerial=${serial}`, "_blank")
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {user?.permission?.home?.read ?
        <Page className="p-3" title="Home API">
          <Header page={"DASHBOARD"} />

          {loading && <Reload />}

          <Card className='mb-3 mt-3 p-3'>
            <Row>
              <Col className='text-center pb-3 pt-3' xs={12} sm={6} md={6} lg={3} xl={3} xxl={3}>
                <MdGroup size={"50"} />
                <h3 className='mb-0'>{n_users}</h3>
                <p style={{ fontSize: 12, marginBottom: 3 }}>Usuários</p>
              </Col>

              <Col className='text-center pb-3 pt-3' xs={12} sm={6} md={6} lg={3} xl={3} xxl={3}>
                <MdExplicit size={"50"} />
                <h3 className='mb-0'>{equipmentMetrics?.count}</h3>
                <p style={{ fontSize: 12, marginBottom: 3 }}>Equipamentos</p>
              </Col>

              <Col className='text-center pb-3 pt-3' xs={12} sm={6} md={6} lg={3} xl={3} xxl={3}>
                <MdStorage size={"50"} />
                <h3 className='mb-0'>{n_measurements}</h3>
                <p style={{ fontSize: 12, marginBottom: 3 }}>Medidas Realizadas</p>
              </Col>

              <Col className='text-center pb-3 pt-3' xs={12} sm={6} md={6} lg={3} xl={3} xxl={3}>
                <MdLock size={"50"} />
                <h3 className='mb-0'>{n_unblocks}</h3>
                <p style={{ fontSize: 12, marginBottom: 3 }}>Ativações de Equipamentos</p>
              </Col>
            </Row>
          </Card>

          <Card className='mb-3 mt-3 p-3'>
            <Row>
              <Col className='text-center' xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <h5 className='mt-3 mb-0'>SIMT</h5>
                <p style={{ fontSize: 12, marginBottom: 3 }}>{equipmentMetrics.simt.n}</p>
                <Button
                  className='mt-1 mb-1'
                  onClick={() => { handlePlanilha("SIMT") }}
                  disabled={user?.permission?.home?.write === true ? false : true}
                  variant="outline-success"
                  size='sm'
                >
                  Relatório
                </Button>

                <HorizontalBar
                  height={160}
                  data={{
                    labels: equipmentMetrics.simt.labels,
                    datasets: [
                      {
                        label: 'Equipamentos',
                        data: equipmentMetrics.simt.data,
                        backgroundColor: 'rgba(18, 88, 25, 0.8)',
                        borderColor: 'black',
                        borderWidth: 1
                      }
                    ]
                  }}
                  options={options}
                />

                <h5 className='mt-3 mb-0'>CPMT</h5>
                <p style={{ fontSize: 12, marginBottom: 3 }}>{equipmentMetrics.cpmt.n}</p>
                <Button
                  className='mt-1 mb-1'
                  onClick={() => { handlePlanilha("CPMT") }}
                  disabled={user?.permission?.home?.write === true ? false : true}
                  variant="outline-success"
                  size='sm'
                >
                  Relatório
                </Button>

                <HorizontalBar
                  height={160}
                  data={{
                    labels: equipmentMetrics.cpmt.labels,
                    datasets: [
                      {
                        label: 'Equipamentos',
                        data: equipmentMetrics.cpmt.data,
                        backgroundColor: 'rgba(18, 88, 25, 0.8)',
                        borderColor: 'black',
                        borderWidth: 1
                      }
                    ]
                  }}
                  options={options}
                />

              </Col>

              <Col className='text-center' xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <h5 className='mt-3 mb-0'>BIMT</h5>
                <p style={{ fontSize: 12, marginBottom: 3 }}>{equipmentMetrics.bimt.n}</p>

                <Button
                  className='mt-1 mb-1'
                  onClick={() => { handlePlanilha("BIMT") }}
                  disabled={user?.permission?.home?.write === true ? false : true}
                  variant="outline-success"
                  size='sm'
                >
                  Relatório
                </Button>

                <HorizontalBar
                  height={160}
                  data={{
                    labels: equipmentMetrics.bimt.labels,
                    datasets: [
                      {
                        label: 'Equipamentos',
                        data: equipmentMetrics.bimt.data,
                        backgroundColor: 'rgba(18, 88, 25, 0.8)',
                        borderColor: 'black',
                        borderWidth: 1
                      }
                    ]
                  }}
                  options={options}
                />

                <h5 className='mt-3 mb-0'>EEMT</h5>
                <p style={{ fontSize: 12, marginBottom: 3 }}>{equipmentMetrics.eemt.n}</p>

                <Button
                  className='mt-1 mb-1'
                  onClick={() => { handlePlanilha("EEMT") }}
                  disabled={user?.permission?.home?.write === true ? false : true}
                  variant="outline-success"
                  size='sm'
                >
                  Relatório
                </Button>

                <HorizontalBar
                  height={160}
                  data={{
                    labels: equipmentMetrics.eemt.labels,
                    datasets: [
                      {
                        label: 'Equipamentos',
                        data: equipmentMetrics.eemt.data,
                        backgroundColor: 'rgba(18, 88, 25, 0.8)',
                        borderColor: 'black',
                        borderWidth: 1
                      }
                    ]
                  }}
                  options={options}
                />
              </Col>

              <Col className='text-center' xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <h5 className='mt-3 mb-0'>ESMR</h5>
                <p style={{ fontSize: 12, marginBottom: 3 }}>{equipmentMetrics.esmr.n}</p>

                <Button
                  className='mt-1 mb-1'
                  onClick={() => { handlePlanilha("ESMR") }}
                  disabled={user?.permission?.home?.write === true ? false : true}
                  variant="outline-success"
                  size='sm'
                >
                  Relatório
                </Button>

                <HorizontalBar
                  height={160}
                  data={{
                    labels: equipmentMetrics.esmr.labels,
                    datasets: [
                      {
                        label: 'Equipamentos',
                        data: equipmentMetrics.esmr.data,
                        backgroundColor: 'rgba(18, 88, 25, 0.8)',
                        borderColor: 'black',
                        borderWidth: 1
                      }
                    ]
                  }}
                  options={options}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Button
                  className='ms-1 mt-3 mb-3'
                  variant="outline-success"
                  href="/equipments"
                >
                  Visualizar
                </Button>

                <Button
                  className='ms-1 mt-3 mb-3'
                  variant="outline-success"
                  onClick={() => { window.open(process.env.REACT_APP_API_GLOBAL_SERVER + "/log", "_blank") }}
                >
                  Download Relatório
                </Button>
              </Col>
            </Row>
          </Card>

          <Card className='mb-3 mt-3 p-3 text-center'>
            <CertificadoDash />
          </Card>

        </Page>
        : <h2 className='mt-3 text-center'> <br /> {' ... '} </h2>
      }
    </>
  )
}

export default Home;
