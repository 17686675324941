import React, { useState } from 'react';
import { FloatingLabel, Form, Badge, Button } from 'react-bootstrap';

const EmailSelect = ({ emails, selectedEmails, setSelectedEmails }) => {
  const [filter, ] = useState('');

  const handleSelectChange = (event) => {
    const value = event.target.value;
    const newSelectedEmails = selectedEmails.includes(value)
      ? selectedEmails.filter(email => email !== value)
      : [...selectedEmails, value];
    setSelectedEmails(newSelectedEmails);
  };

  const filteredEmails = emails.filter(email =>
    email.toLowerCase().includes(filter.toLowerCase())
  );

  const handleRemoveEmail = (emailToRemove) => {
    setSelectedEmails(selectedEmails.filter(email => email !== emailToRemove));
  };

  return (
    <>
      <FloatingLabel controlId="floatingSelect" label="Filtro">
        <Form.Control
          as="select"
          onChange={handleSelectChange}
          value=""
        >
          <option value=""></option>
          {filteredEmails.map((email, index) => (
            <option key={index} value={email}>
              {email}
            </option>
          ))}
        </Form.Control>
      </FloatingLabel>

      <div style={{ fontSize: 14 }}>
        {selectedEmails.length > 0 ? (
          selectedEmails.map((email, index) => (
            <Badge key={index} pill bg="success" className="me-1 mt-1">
              {email}
              <Button
                style={{ fontSize: 12 }}
                variant="close"
                className="ms-1"
                onClick={() => handleRemoveEmail(email)}
              />
            </Badge>
          ))
        ) : (
          <div>Nenhum filtro selecionado</div>
        )}
      </div>
    </>
  );
};

export default EmailSelect;
