import React, { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { getToken } from 'actions/sessionActions';
import { Page } from 'components';
import { Download, Results } from './components';
import { Card, Row, Col, Button } from 'react-bootstrap';
import Header from '../../components/Header/Header';
import Reload from '../../components/Reload/Reload';
import axios from 'utils/axios';

const Relatorio = () => {
  const [equipments, setEquipments] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      var data1 = {}
      const filter = localStorage.getItem("EQUIP_FILTER");
      const globalEquipments = JSON.parse(localStorage.getItem("EQUIPMENTS"));

      await axios(process.env.REACT_APP_API_GERA_SENHA, getToken())
        .post(`/log/unlockfilter`, { "filter": JSON.parse(filter) })
        .then((response) => {
          var equipments = response.data;
          data1 = equipments;

          let equipLog = JSON.parse(localStorage.getItem("tempEquipments"));

          equipments = equipments.map(equip => {
            let temp = Object.values(equipLog).find(e => e.serialnumber === equip.serialnumber) || null;
            if (temp) {
              equip.wf = temp.wf === 'OK' ? 'Sim' : "Não";
              equip.calph = temp.calph === 'OK' ? 'Sim' : "Não";
            }
            let e = globalEquipments.filter((i) => i.serialnumber === equip.serialnumber);
            if (e.length > 0) {
              equip.dcal = e[0].dcal;
            }
            return equip;
          });

          let users = equipments.map((equip) => {
            return equip.email;
          });

          axios(process.env.REACT_APP_API_MOTOMCO, getToken())
            .post('/usersfilter', { filter: users })
            .then((response) => {
              let users = response.data;

              for (let j = 0; j < equipments.length; j++) {
                equipments[j].user = users.find((element) => element.email === equipments[j].email);
              }
            });
        });

      await axios(process.env.REACT_APP_API_GLOBAL_SERVER, getToken())
        .post(`/equipment/filter`, { "filter": JSON.parse(filter) })
        .then((response) => {
          handleData(data1, response.data);
        })
      setLoading(false);
    })();
  }, []);

  const handleData = async (data1, data2) => {
    const data1Map = data1.reduce((acc, item) => {
      acc[item.serialnumber] = item;
      return acc;
    }, {});

    const updatedData = data2.map((item) => {
      const correspondingData1Item = data1Map[item.serialnumber];
      const filCopo = Number(item.copo);

      const copo =
        filCopo === 0
          ? 'Sem dados'
          : filCopo === 150 || filCopo > 150
            ? `Pequeno | ${filCopo}`
            : `Grande | ${filCopo}`;

      return {
        ...item,
        formatedAddr: item.address?.formatedAddr || '',
        copo: copo || '',
        email: correspondingData1Item?.email || '', // Adiciona o campo `email` de data1, se existir
        ...correspondingData1Item,
      };
    });

    setEquipments(updatedData);
  };

  const sortBySN = () => {
    equipments
      .sort(function (a, b) {
        return a.serialnumber.split('-')[1] - b.serialnumber.split('-')[1];
      })
      .sort();

    var list = [];
    equipments.forEach((e) => {
      list.push(e);
    });
    setEquipments(list);
  };

  const sortByDate = () => {
    equipments.sort(function (a, b) {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    });

    var list = [];
    equipments.forEach((e) => {
      list.push(e);
    });

    setEquipments(list);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <Page className="p-3" title="Relatório">
      <Header page={"Relatório"} />

      {loading && <Reload />}

      <div>
        Ordenar lista por:
      </div>

      <Card className='mb-3 mt-2 p-2'>
        <Row>
          <Col>
            <Button
              className='ms-2 mt-2 mb-2'
              onClick={sortBySN}
              variant='success'
            >
              Número Serial
            </Button>

            <Button
              className='ms-2 mt-2 mb-2'
              onClick={sortByDate}
              variant='success'
            >
              Data
            </Button>

            <Button
              className='ms-2 mt-2 mb-2'
              onClick={handlePrint}
              variant="success"
            >
              Imprimir
            </Button>

            <Download
              data={equipments}
            />
          </Col>
        </Row>
        <Row>
          <Col>
          </Col>
        </Row>
      </Card>

      {equipments.length &&
        <div ref={componentRef}>
          {equipments && (
            <Results data={equipments} />
          )}
        </div>
      }

    </Page>
  );
};

export default Relatorio;
