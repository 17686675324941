import React from 'react';
import { Card, Table } from 'react-bootstrap';
import dateformat from 'utils/dateformat';

const Results = ({ data }) => {

  return (
    <div>
      <Card>
        <Card.Header>
          <h5 className='mt-2 mb-2'>Ativações</h5>
        </Card.Header>
        <Card.Body>
          {data && data.map((data) => (
            <div key={data._id || ''} className="mb-4">

              <Table style={{ fontSize: 15 }} striped bordered hover responsive size='sm'>
                <thead className="table-light">
                  <tr class="table-active">
                    <th className="col-sm-2 text-center">SERIAL</th>
                    <th className="col-sm-2 text-center">SOFTWARE</th>
                    <th className="col-sm-2 text-center">DATA</th>
                    <th className="col-sm-2 text-center">E-MAIL</th>
                    <th className="col-sm-2 text-center">CPF/CNPJ</th>
                    <th className="col-sm-2 text-center">COPO</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center pt-2 pb-2">{data.serialnumber || '--'}</td>
                    <td className="text-center pt-2 pb-2">{data.versaosw || '--'}</td>
                    <td className="text-center pt-2 pb-2">{data.updatedAt ? dateformat(data.updatedAt, "UTC:dd/mm/yy' 'HH:MM") : '--'}</td>
                    <td className="text-center pt-2 pb-2">{data.email || '--'}</td>
                    <td className="text-center pt-2 pb-2">{data.user?.cpfcnpj || '--'}</td>
                    <td className="text-center pt-2 pb-2">{data.copo || 'Software Desatualizado'}</td>
                  </tr>
                </tbody>
              </Table>

              <Table style={{ fontSize: 15 }} striped bordered hover responsive size='sm'>
                <thead className="table-light">
                  <tr class="table-active">
                    <th className="col-sm-2 text-center">LATITUDE</th>
                    <th className="col-sm-2 text-center">LONGITUDE</th>
                    <th className="col-sm-2 text-center">ID DO COPO</th>
                    <th className="col-sm-2 text-center">CHIP WI-FI</th>
                    <th className="col-sm-2 text-center">PH CALIBRADO</th>
                    <th className="col-sm-2 text-center">DATA CALIBRAÇÃO</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center pt-2 pb-2">{data.lat || '--'}</td>
                    <td className="text-center pt-2 pb-2">{data.lng || '--'}</td>
                    <td className="text-center pt-2 pb-2">{data.cupid || '--'}</td>
                    <td className="text-center pt-2 pb-2">{data.wf || '--'}</td>
                    <td className="text-center pt-2 pb-2">{data.calph || '--'}</td>
                    <td className="text-center pt-2 pb-2">{data.dcal || '--'}</td>
                  </tr>
                </tbody>
              </Table>

            </div>
          ))}
        </Card.Body>
      </Card>
    </div>
  );
};

export default Results;
