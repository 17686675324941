import React from 'react';
import sjcl from 'sjcl'
import clsx from 'clsx';
import 'react-multi-email/dist/style.css';
import { makeStyles } from '@material-ui/styles';

import { ReactMultiEmail, isEmail } from 'react-multi-email';

import { Alert, SearchLocationInput } from 'components';

import {
  Checkbox,
  Typography,
  Card,
  CardContent,
  CardActions,
  TextField,
  Button
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '30px'
  },
  alert: {
    marginBottom: theme.spacing(3)
  },
  formGroup: {
    marginBottom: theme.spacing(3)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  fieldHint: {
    margin: theme.spacing(1, 0)
  },
  tags: {
    marginTop: theme.spacing(1),
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  dateField: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  }
}));

const Form = (props) => {
  const {
    alertMessage,
    cities,
    className,
    fieldChange,
    onSubmit,
    states,
    units,
    permission,
    setEmails,
    emails,
    emailsValue,
    setUnits,
    setLocation,
    ...rest
  } = props;

  const classes = useStyles();

  const { master } = JSON.parse(localStorage.getItem('userData'));

  const handleValidate = () => {
    var pass = units.password
    units.password = pass
    if (!units.password) {
      delete units.password
      onSubmit()
    } else if (units.password.length > 4) {
      onSubmit()
    } else {
      alert('Digite uma senha maior!')
    }
  }

  const handelHash = () => {
    if (!units.secretOnlyKey) {
      const myBitArray = sjcl.hash.sha256.hash(units._id);
      const myHash = sjcl.codec.hex.fromBits(myBitArray);
      units.secretOnlyKey = myHash
      setUnits({ ...units });
    } else {
      return;
    }
  }

  return (
    <React.Fragment>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent>
          <form>
            {alertMessage.message && (
              <Alert
                className={classes.alert}
                message={alertMessage.message}
                variant={alertMessage.type}
              />
            )}
            <div className='mb-4'>
              <Checkbox
                disabled={permission === true ? false : true}
                checked={units.typeCompany}
                onChange={event =>
                  fieldChange(event, 'typeCompany', !units.typeCompany)
                }
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <Typography variant="inherit">Cooperativa Matriz</Typography>
            </div>


            <div className={classes.formGroup}>
              <form autoComplete="off">
                <TextField
                  disabled={permission === true ? false : true}
                  fullWidth
                  label="Nome da Empresa"
                  name="name"
                  onChange={event =>
                    fieldChange(event, 'name', event.target.value)
                  }
                  value={units.name}
                  variant="outlined"
                />
              </form>
            </div>

            <div className={classes.formGroup}>
              <form autoComplete="off">
                <TextField
                  disabled={permission === true ? false : true}
                  fullWidth
                  label="CNPJ"
                  name="cnpj"
                  onChange={event =>
                    fieldChange(event, 'cnpj', event.target.value)
                  }
                  value={units.cnpj}
                  variant="outlined"
                />
              </form>
            </div>

            <div className={classes.formGroup}>
              <form autoComplete="off">
                <SearchLocationInput
                  setLocation={setLocation}
                  addr={units.address}
                />
              </form>
            </div>

            <div className={classes.formGroup}>
              <form autoComplete="off">
                <TextField
                  disabled={permission === true ? false : true}
                  fullWidth
                  label="Número do Contrato de Locação"
                  name="ncontract"
                  onChange={event =>
                    fieldChange(event, 'ncontract', event.target.value)
                  }
                  value={units.ncontract}
                  variant="outlined"
                />
              </form>
            </div>

            <div className={classes.formGroup}>
              <form autoComplete="off">
                <TextField
                  disabled={permission === true ? false : true}
                  fullWidth
                  label="Telefone"
                  name="fone"
                  onChange={event =>
                    fieldChange(event, 'fone', event.target.value)
                  }
                  value={units.fone}
                  variant="outlined"
                />
              </form>
            </div>
            <div className={classes.formGroup}>
              <form autoComplete="off">
                <TextField
                  disabled={permission === true ? false : true}
                  fullWidth
                  label="E-mail"
                  name="email"
                  onChange={event =>
                    fieldChange(event, 'email', event.target.value)
                  }
                  value={units.email}
                  variant="outlined"
                />
              </form>
            </div>

            <div>
              <Checkbox
                disabled={permission === true ? false : true}
                checked={units.restrictAcess}
                onChange={event =>
                  fieldChange(event, 'restrictAcess', !units.restrictAcess)
                }
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <Typography variant="inherit">Restringir ativação a usuários da empresa</Typography>
            </div>

            <div className={classes.formGroup} style={{ 'marginTop': "20px" }}>
              <form autoComplete="off">
                <TextField
                  disabled={permission === true ? false : true}
                  fullWidth
                  label="Domínio de E-mail"
                  name="email"
                  onChange={event =>
                    fieldChange(event, 'dominioEmail', event.target.value.trim())
                  }
                  value={units.dominioEmail}
                  variant="outlined"
                />
              </form>
              <label>Todos os usuários relacionados a este domínio de E-mail serão adicionados como membros desta empresa.</label>
            </div>

            <div className={classes.formGroup}>
              <form autoComplete="off">
                <ReactMultiEmail
                  className=''
                  placeholder="E-mails de acesso ao SGU"
                  emails={emails}
                  onChange={(_emails) => {
                    var email = _emails;
                    setEmails(email)
                  }}
                  validateEmail={email => {
                    return isEmail(email); // return boolean
                  }}
                  getLabel={(
                    email,
                    index,
                    removeEmail,
                  ) => {
                    return (
                      <div data-tag key={index}>
                        {email}
                        <span data-tag-handle onClick={() => removeEmail(index)}>
                          ×
                        </span>
                      </div>
                    );
                  }}
                />
              </form>
              <label>E-mails de administradores.</label>
            </div>

            <div className={classes.formGroup}>
              <form autoComplete="off">
                <TextField
                  disabled={true}
                  fullWidth
                  label="Chave única API externa"
                  name="secretOnlyKey"
                  value={units.secretOnlyKey}
                  variant="outlined"
                />
              </form>
            </div>

            <div>
              <Checkbox
                disabled={permission === true ? false : true}
                checked={units.accessSecretOnlyKey}
                onChange={event => {
                  handelHash()
                  fieldChange(event, 'accessSecretOnlyKey', !units.accessSecretOnlyKey)
                }}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <Typography variant="inherit">Acesso API externa</Typography>
            </div>

            <div>
              <Checkbox
                disabled={permission === true && units.crossCheck === false ? false : master === true ? false : true}
                checked={units.crossCheck}
                onChange={event => {
                  units.crossCheck = event.target.checked
                  console.log(units.crossCheck)
                  setUnits({ ...units })
                }}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <Typography variant="inherit">Ativar Crosscheck para essa Unidade</Typography>
            </div>

          </form>
        </CardContent>
        <CardActions>
          <Button
            className={classes.saveButton}
            color="primary"
            disabled={permission === true ? false : true}
            onClick={() => handleValidate()}
            variant="contained"
          >
            Salvar
          </Button>
        </CardActions>
      </Card>
    </React.Fragment>
  )
}
export default Form;
