import React, { useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { getToken } from 'actions';
import CryptoJS from 'crypto-js';
import JSZip from 'jszip';
import ApiMServer from 'services/ApiMServer';

const FileUpload = (props) => {
  const {
    msg,
    type,
    company,
    permission,
    handleGetChart,
    handleAlertModalError,
    handleAlertModalSuccess,
  } = props;

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setFileName(event.target.files[0]?.name || '');
  };

  const handleConfirm = async () => {
    if (msg) {
      var conf = window.confirm(msg)
      if (conf) {
        handleUpload()
      } else {
        return
      }
    }
    handleUpload()
  }

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Pasta não selecionada!")
      return;
    }

    try {
      const fileReader = new FileReader();
      fileReader.onload = async (e) => {
        const arrayBuffer = e.target.result;
        const zip = await JSZip.loadAsync(arrayBuffer);

        const curvaEntry = zip.file('curva.txt');
        const curvaSignin = zip.file('assinatura.txt');

        if (!curvaSignin || !curvaEntry) {
          alert("Erro ao ler arquivo!");
          return;
        }
        const signin_date = await curvaSignin.async('string');
        const lines = signin_date.split(/\r?\n/);
        const HASH = lines[1];
        const CHART_DATE = lines[9].split(" ")[1];

        if (!HASH || !CHART_DATE) {
          alert("Erro ao ler curva");
          return;
        }
        const chart_date = await curvaEntry.async('string');
        const test_hash = CryptoJS.SHA1(chart_date).toString();

        if (HASH !== test_hash) {
          alert("Erro no hash");
          return;
        }
        const chartLines = chart_date.split(/\r?\n/);
        const chartPack = convertChartsToJson(chartLines, HASH, CHART_DATE, type);

        handleSubmitChartPack(chartPack)
      };

      fileReader.readAsArrayBuffer(selectedFile);
    } catch (error) {
      console.error("Error processing file:", error);
    }
  };

  const getSubstrings = (stringSubject, string1, string2) => {
    let myList = [];
    let intstart = 0;
    const strlength = stringSubject.length;
    let continueloop = true;

    while (intstart < strlength && continueloop) {
      let intindex1 = stringSubject.indexOf(string1, intstart);
      if (intindex1 !== -1) {
        intindex1 += string1.length;
        let intindex2 = stringSubject.indexOf(string2, intindex1);
        if (intindex2 !== -1) {
          const subsequence = stringSubject.substring(intindex1, intindex2);
          myList.push(subsequence);
          intstart = intindex2 + string2.length;
        } else {
          continueloop = false;
        }
      } else {
        continueloop = false;
      }
    }
    return myList;
  };

  const convertChartsToJson = (chart, HASH, CHART_DATE, CHART_TYPE) => {
    let curvas = [];

    for (let line of chart) {
      if (!line) continue;
      let s = line.trim();
      let l = new Set(getSubstrings(s, "<", ">"));
      let j = {};

      l.forEach(i => {
        const regex = new RegExp(`<${i}>(.*?)<${i}>`);
        const result = s.match(regex);
        if (result) {
          let val = result[1];
          if (val.includes(";")) {
            if (i === "nome") {
              j[i] = val.split(";").map(v => v.trim());
            } else {
              j[i] = val.split(";").map(v => v.trim());
            }
          } else {
            j[i] = val.trim();
          }
        }
      });

      j["_id"] = CryptoJS.SHA256(JSON.stringify(j)).toString();
      j["hash"] = HASH;
      j["data_pack"] = CHART_DATE;
      j["chart_type"] = CHART_TYPE;
      curvas.push(j);
    }

    return curvas;
  };

  const handleSubmitChartPack = async (chartPack) => {
    try {
      await ApiMServer.post(`/api/v1/upcharts`, { token: getToken(), chartPack: chartPack, company: company, type: type });
      if (handleGetChart) {
        handleGetChart()
      }
      setSelectedFile(null)
      setFileName("")
      if (handleAlertModalSuccess) {
        handleAlertModalSuccess('Update realizado com sucesso!')
      }
    } catch (error) {
      if (handleAlertModalError) {
        handleAlertModalError('Erro ao realizar upload!')
      }
      console.log(error);
    }
  }

  return (
    <Row>
      <Col>
        <Form>
          <Form.Group controlId="formFile" className="mb-2">
            <Form.Control
              type="file"
              onChange={handleFileChange}
              disabled={permission === true ? false : true}
            />
          </Form.Group>
          {fileName && (
            <p className="mb-3">
              <strong>Arquivo Selecionado:</strong> {fileName}
            </p>
          )}
          <Button
            variant="success"
            onClick={handleConfirm}
            disabled={!selectedFile}
          >
            Enviar
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

export default FileUpload;
