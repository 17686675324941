import React from 'react';
import { Card, Form, Row, Col, FloatingLabel } from 'react-bootstrap';

const Form2 = (props) => {
  const { unlock } = props;

  return (
    <Card className='mb-3 mt-3'>
      <Card.Header>
        <h5 className='mt-2 mb-2'>Última Ativação</h5>
      </Card.Header>
      <Card.Body>
        <Form>
          <Row className="mb-3 g-2">

            <Col sm={6} xs={12}>
              <FloatingLabel
                controlId="floatingName"
                label="Nome"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  value={unlock.name || ''}
                  readOnly
                  placeholder="Nome"
                  disabled
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingEmail"
                label="E-mail"
                className="mb-3"
              >
                <Form.Control
                  type="email"
                  value={unlock.email || ''}
                  readOnly
                  placeholder="E-mail"
                  disabled
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingDate"
                label="Data"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  value={unlock.updatedAt || ''}
                  readOnly
                  placeholder="Data"
                  disabled
                />
              </FloatingLabel>
            </Col>

            <Col sm={6} xs={12}>
              <FloatingLabel
                controlId="floatingLat"
                label="Latitude"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  value={unlock.lat || ''}
                  readOnly
                  placeholder="Latitude"
                  disabled
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingLng"
                label="Longitude"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  value={unlock.lng || ''}
                  readOnly
                  placeholder="Longitude"
                  disabled
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingAddress"
                label="Endereço"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  value={unlock.address || ''}
                  readOnly
                  placeholder="Endereço"
                  disabled
                />
              </FloatingLabel>
            </Col>

          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default Form2;
