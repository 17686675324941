import axios from "axios";
import { getToken } from "actions";

const Api = axios.create({
  baseURL: process.env.REACT_APP_API_MONITORA,
});

Api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default Api;
