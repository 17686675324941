import React from 'react';
import { Alert, SearchLocationInput } from 'components';
import { Card, Row, Col, Button, FloatingLabel, Form } from 'react-bootstrap';

const Forms = props => {
  const { permission, fieldChange, onSubmit, units, setLocation } = props;

  const formatCNPJ = (value) => {
    if (!value) return value;

    const cnpj = value.replace(/\D/g, '');
    if (cnpj.length <= 14) {
      return cnpj
        .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
    }
    return cnpj;
  };

  const handleCNPJChange = (event) => {
    const rawValue = event.target.value;
    const formattedCNPJ = formatCNPJ(rawValue);
    fieldChange(event, 'cnpj', formattedCNPJ);
  };

  const handlePhoneChange = (event) => {
    const rawValue = event.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
    let formattedValue = rawValue;

    if (rawValue.length > 2 && rawValue.length <= 7) {
      formattedValue = `(${rawValue.slice(0, 2)}) ${rawValue.slice(2)}`;
    } else if (rawValue.length > 7) {
      formattedValue = `(${rawValue.slice(0, 2)}) ${rawValue.slice(2, 7)}-${rawValue.slice(7, 11)}`;
    }

    fieldChange(event, 'fone', formattedValue);
  };

  return (
    <Card className='mt-3 p-3'>
      <Row>
        <Col>
          <FloatingLabel
            controlId="floatingName"
            label="Nome da Cooperativa"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Nome da Cooperativa"
              name="name"
              onChange={event => fieldChange(event, 'name', event.target.value)}
              value={units.name}
              maxLength={100}
            />
          </FloatingLabel>

          <FloatingLabel controlId="floatingCNPJ" label="CNPJ" className="mb-3">
            <Form.Control
              type="text"
              placeholder="CNPJ"
              name="cnpj"
              autoComplete="off"
              onChange={handleCNPJChange}
              value={units.cnpj}
              maxLength={18}
            />
          </FloatingLabel>

          <SearchLocationInput
            setLocation={setLocation}
            addr={units.address}
          />
        </Col>

        <Col>

          <FloatingLabel
            controlId="floatingPhone"
            label="Número do contrato"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Número do contrato"
              name="ncontract"
              autoComplete="off"
              onChange={(event) => fieldChange(event, 'ncontract', event.target.value)}
              value={units.ncontract}
              maxLength={45}
            />
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingPhone"
            label="Telefone"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Telefone"
              name="fone"
              autoComplete="off"
              onChange={(event) => handlePhoneChange(event)}
              value={units.fone}
              maxLength={15}
            />
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingEmail"
            label="E-mail"
            className="mb-3"
          >
            <Form.Control
              type="email"
              placeholder="E-mail"
              name="email"
              autoComplete="off"
              onChange={(event) => fieldChange(event, 'email', event.target.value)}
              value={units.email}
              maxLength={100}
            />
          </FloatingLabel>

        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            onClick={() => { onSubmit() }}
            variant="success"
            disabled={!permission?.cooperatives?.write}
          >
            Salvar
          </Button>

          <Button
            onClick={() => { window.history.back() }}
            variant="secondary"
            className='mx-2'
          >
            Cancelar
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default Forms;
