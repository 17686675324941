import React, { useState, useEffect } from 'react';
import axios from 'utils/axios';
import { getToken } from 'actions/sessionActions';
import { Page, SearchBar } from 'components';
import { Results } from './components';
import { HorizontalBar } from 'react-chartjs-2';
import Alert from 'react-bootstrap/Alert';
import Reload from '../../components/Reload/Reload';
import Header from '../../components/Header/Header';
import { Form, Button, Row, Col } from 'react-bootstrap'

const EquipmentsQL = (props) => {
  const [equipments, setEquipments] = useState([]);
  const [allEquipments, setAllEquipments] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [count, setCount] = useState('---');
  const [nEquipments, setNEquipments] = useState(0);
  const [loading, setLoading] = useState(true);

  const [errors, setErrors] = useState({ labels: [], datasets: [] });

  const [date1, setDate1] = useState('');
  const [date2, setDate2] = useState('');

  const getEquipments = async (page) => {
    try {
      const { data } = await axios(process.env.REACT_APP_API_QUALIDADE, getToken()).get(`/equipments?page=${page}`);
      const equipments = data.data
        .filter((item) => {
          return item.serialnumber != null;
        })
        .sort(function (a, b) {
          return new Date(b.updatedAt) - new Date(a.updatedAt);
        });
      setEquipments(equipments);
      setAllEquipments(equipments);
      setNEquipments(data.n_equipments);
      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };

  const getEquipmentsFilter = (filter) => {
    (async () => {
      const resp = await axios(
        process.env.REACT_APP_API_QUALIDADE,
        getToken()
      ).get(`/equipments/filter?sequence=${filter}`);
      setEquipments(resp.data);
    })();
  };

  const handleFilterErrors = () => {
    if (date1 && date2) {
      let d1 = new Date(date1);
      let d2 = new Date(date2);
      if (d1 > d2) {
        alert('A segunda data informada precisa ser maior ou igual a primeira');
      } else {
        setLoading(true);
        let equips = allEquipments.filter(i => {
          let userTimezoneOffset = new Date().getTimezoneOffset() * 60000;
          var d = new Date(i.updatedAt.split('T')[0]);
          d = new Date(d.getTime() + userTimezoneOffset);
          var d1 = new Date(date1);
          d1 = new Date(d1.getTime() + userTimezoneOffset);
          var d2 = new Date(date2);
          d2 = new Date(d2.getTime() + userTimezoneOffset);
          if ((d >= d1) && (d <= d2)) {
            return true;
          } else {
            return false;
          }
        });
        setEquipments(equips);
        getErrors([d1.toDateString(), d2.toDateString()]);
      }
    }
  };

  const getCount = (serie) => {
    (async () => {
      const { data } = await axios(
        process.env.REACT_APP_API_QUALIDADE,
        getToken()
      ).get(`/count`);
      setCount(data.count);
    })();
  };

  const getErrors = async (filter) => {
    let headers = {};
    if (date1 && date2) {
      headers.date1 = date1;
      headers.date2 = date2;
    }
    // eslint-disable-next-line react/prop-types
    await axios(process.env.REACT_APP_API_QUALIDADE, getToken())
      .get(`/errors`, {
        headers: headers
      })
      .then((response) => {
        var data = response.data;
        data = data.sort((a, b) => {
          return b[1] - a[1];
        });
        const erros = {
          labels: data.map((item) => {
            return item[0];
          }),
          datasets: [
            {
              label: 'Erros',
              borderColor: 'rgba(0,0,0,1)',
              borderWidth: 2,
              backgroundColor: [
                '#990000',
                '#b30000',
                '#cc0000',
                '#e60000',
                '#ff0000',
                '#ff1a1a',
                '#ff3333',
                '#ff4d4d',
                '#ff6666',
                '#ff8080',
                '#ff9999',
                '#ffb3b3',
                '#ffccccC'
              ],
              data: data.map((item) => {
                return item[1];
              })
            }
          ]
        };
        setErrors(erros);
      });
    setLoading(false);
  };

  useEffect(() => {
    getEquipments(1);
    getCount();
    getErrors([]);
  }, []);

  const handleFilter = () => { };
  const handleSearch = () => {
    if (searchData === '') {
      getEquipments(1);
    } else {
      getEquipmentsFilter(searchData);
    }
  };

  const handleSearchData = (e) => {
    setSearchData(e.target.value);
  };

  return (
    <Page className="p-3" title="Equipamentos">
      <Header page={"Qualidade"} />
      <SearchBar
        onFilter={handleFilter}
        onSearch={handleSearch}
        onSearchData={handleSearchData}
        searchData={searchData}
      />

      <Row className="align-items-end">
        <Col xs={12} sm={5} md={4} lg={3}>
          <Form.Group controlId="date1">
            <Form.Label>Início</Form.Label>
            <Form.Control
              type="date"
              value={date1}
              onChange={(event) => setDate1(event.target.value)}
            />
          </Form.Group>
        </Col>

        <Col xs={12} sm={5} md={4} lg={3}>
          <Form.Group controlId="date2">
            <Form.Label>Fim</Form.Label>
            <Form.Control
              type="date"
              value={date2}
              onChange={(event) => setDate2(event.target.value)}
            />
          </Form.Group>
        </Col>

        <Col xs={12} sm={2} md={4} lg={2}>
          <Button variant="success" onClick={handleFilterErrors} className="w-100">
            Buscar
          </Button>
        </Col>
      </Row>

      <Alert variant="success" className='mt-4 mb-4 p-2'>
        <p style={{ marginBottom: 0 }}>
          NÚMERO DE TESTES: {count}
        </p>
      </Alert>

      {loading && <Reload />}

      {equipments && (
        <Results
          data={equipments}
          nEquipments={nEquipments}
          getEquipments={getEquipments}
        />
      )}

      {/*    <Button
        style={{ margin: 10 }}
        variant='outlined'
        href='http://ec2-user@ec2-34-196-195-39.compute-1.amazonaws.com:6600/errors/download'
      >
        Download Log Erros
      </Button> */}

      {errors.datasets.length ? (
        <div style={{ marginTop: 20, height: 700, width: '100%' }}>
          <HorizontalBar
            data={errors}
            height={150}
            options={{
              title: {
                display: true,
                text: 'Erros no Processo de Qualidade - Última Semana',
                fontSize: 22,
                fontColor: '#8fad15'
              },
              legend: {
                display: true,
                position: 'top',
                labels: {
                  fontSize: 14
                }
              },
              tooltips: {
                callbacks: {
                  label: function (tooltipItem, data) {
                    const value = tooltipItem.xLabel;
                    const totalErros = data.datasets[0].data.reduce((a, b) => a + b, 0);
                    return `${value} erros (${((value / totalErros) * 100).toFixed(2)}%)`;
                  }
                }
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      callback: (value) => `${value} erros`
                    },
                    barThickness: 20
                  }
                ]
              },
              responsive: true,
              maintainAspectRatio: false
            }}
          />
        </div>
      ) : null}
    </Page>
  );
};

export default EquipmentsQL;
