import React from 'react';
import { Card, Form, FloatingLabel, Row, Col, Button } from 'react-bootstrap';
import { Label } from 'semantic-ui-react';
import { SearchLocationInput } from 'components';

const Form3 = (props) => {
  const {
    fieldChange,
    onSubmit,
    permission,
    password,
    setLocation
  } = props;

  return (
    <Card className='mb-3 mt-3'>
      <Card.Header>
        <h5 className='mt-2 mb-2'>Gerar Senha</h5>
      </Card.Header>
      <Card.Body>
        <Form>

          {password ?
            <Label
              className="d-block text-black fw-bold mb-3"
              disabled={!permission}
            >
              {password}
            </Label>
            : null}

          <Row className="mb-3 g-2">
            <Col sm={6} xs={12}>
              <FloatingLabel
                controlId="floatingToken"
                label="Token"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Token"
                  name="token"
                  autoComplete="off"
                  disabled={!permission}
                  onChange={(event) => fieldChange(event, 'token', event.target.value)}
                />
              </FloatingLabel>

              <SearchLocationInput setLocation={setLocation} />

              <Button
                disabled={!permission}
                onClick={() => onSubmit()}
                variant="success"
              >
                Gerar
              </Button>

            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default Form3;
