import React, { useState } from 'react';
import { Form, FormControl, InputGroup, Row, Col } from 'react-bootstrap';
import { IoSearch } from "react-icons/io5";

const Filter = (props) => {
  const { filter, companyNames, count } = props;

  const [checkBox, setCheckBox] = useState([true, false, false, false, false, false, false, false, false]);
  const [company, setCompany] = useState('');
  const [serialNumber, setSerialNumber] = useState('');

  const handleCheckBoxChange = (position) => {
    let checkVals = [...checkBox];
    checkVals[0] = false;
    checkVals[position] = !checkVals[position];

    if (checkVals.every(val => val === false)) {
      checkVals[0] = true;
    }

    if (position === 0) {
      checkVals.fill(false);
      checkVals[0] = true;
    } else if (position === 7) {
      checkVals[8] = false;
    } else if (position === 8) {
      checkVals[7] = false;
    }

    setCheckBox(checkVals);
    filter(checkVals, company, serialNumber);
  };

  const handleCompanyChange = (event) => {
    setCompany(event.target.value);
    filter(checkBox, event.target.value, serialNumber);
  };

  const handleSerialNumberChange = (event) => {
    setSerialNumber(event.target.value.toUpperCase());
    filter(checkBox, company, event.target.value.toUpperCase());
  };

  return (
    <div style={{ padding: "12px 12px", backgroundColor: '#1a1a1a', color: 'white', height: "10vh" }}>
      <Row className="align-items-center">
        {['TODOS', 'LOCAÇÃO', 'SIMT', 'BIMT', 'CPMT', 'EEMT', 'ESMR', 'ESTADOS', 'CIDADES'].map((label, index) => (
          <Col key={index} xs="auto">
            <Form.Check id={`checkbox-${index}`}>
              <Form.Check.Input
                type="checkbox"
                checked={checkBox[index]}
                onChange={() => handleCheckBoxChange(index)}
                isValid
              />
              <Form.Check.Label style={{ color: "#f4f4f4" }}>{label}</Form.Check.Label>
            </Form.Check>
          </Col>
        ))}

        <Col xs="auto">
          <InputGroup>
            <InputGroup.Text style={{ backgroundColor: '#1a1a1a', border: '1px solid white' }}>
              <IoSearch color="#f4f4f4" size={20} />
            </InputGroup.Text>
            <FormControl
              size='sm'
              placeholder="Número de Série"
              value={serialNumber}
              onChange={handleSerialNumberChange}
              style={{
                backgroundColor: '#1a1a1a',
                color: 'white',
                border: '1px solid white',
              }}
            />
          </InputGroup>
        </Col>

        <Col md="auto">
          <Form.Group>
            <Form.Select
              size='sm'
              value={company}
              onChange={handleCompanyChange}
              style={{
                backgroundColor: '#1a1a1a',
                color: 'white',
                border: '1px solid white',
              }}>
              <option value="">Cooperativas</option>
              <option value="">Todas</option>
              {companyNames && companyNames.map((comp) => (
                <option key={comp} value={comp}>{comp}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>

        <Col xs="auto">
          <strong style={{ color: 'white' }}>TOTAL: {count || 0}</strong>
        </Col>
      </Row>
    </div>
  );
};

export default Filter;
