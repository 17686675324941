/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import useRouter from 'utils/useRouter';
import { Container } from 'react-bootstrap';

const Page = (props) => {
  const { title, children, ...rest } = props;

  const router = useRouter();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', {
        page_path: router.location.pathname,
        page_name: title
      });
    }
  }, []);

  return (
    <Container {...rest} className='pt-3 pb-3' fluid>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Container>
  );
};

export default Page;
