import React from 'react';
import { Button } from 'react-bootstrap';
import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Download = (props) => {
  const { data } = props;

  return (
    <div>
      <ExcelFile element={
        <Button
          className='ms-2 mt-2 mb-2'
          variant='success'
          disabled={!data.length}
        >
          Download
        </Button>
      }>
        {data.length ? (
          <ExcelSheet data={data} name="Relatório">
            <ExcelColumn label="Data" value="updatedAt" />
            <ExcelColumn label="Número Serial" value="serialnumber" />
            <ExcelColumn label="ID do Copo" value="cupid" />
            <ExcelColumn label="Versão de Software" value="versaosw" />
            <ExcelColumn label="Nome" value="name" />
            <ExcelColumn label="E-mail" value="email" />
            <ExcelColumn label="Latitude" value="lat" />
            <ExcelColumn label="Longitude" value="lng" />
            <ExcelColumn label="Endereço" value="formatedAddr" />
            <ExcelColumn label="Chip WI-FI" value="wf" />
            <ExcelColumn label="PH Calibrado" value="calph" />
            <ExcelColumn label="Data de Calibração" value="dcal" />
            <ExcelColumn label="Tamanho do Copo" value="copo" />
          </ExcelSheet>
        ) : null}
      </ExcelFile>
    </div>
  );
};

export default Download;
